import './Event.scoped.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFire, faMountainSun, faRoad, faMeteor } from '@fortawesome/free-solid-svg-icons';
import { getColorForName, getJerseyIconImage, getColorForType, formatDate } from '../../utils';

function Event(props){
    //console.log(props);
    return (
        <div className="component">
            <div className={`event ${props.wide ? 'wide' : ''}`} style={{ backgroundColor: getColorForName(props.eventKey)}}>
                
                <div className="titlebar">   
                    <div className='icon'>
                        {props.type.indexOf('Road') >= 0 && <FontAwesomeIcon icon={faRoad} className="scaledown" style={{ color: getColorForType(props.type)}} />}
                        {props.type.indexOf('Hill Climb') >= 0 && <FontAwesomeIcon icon={faMountainSun} className="scaledown" style={{ color: getColorForType(props.type)}} />}
                        {props.type.indexOf('Criterium') >= 0 && <FontAwesomeIcon icon={faFire} style={{ color: getColorForType(props.type)}} />}
                        {props.type.indexOf('Circuit') >= 0 && <FontAwesomeIcon icon={faMeteor} className="rotate" style={{ color: getColorForType(props.type)}} />}
                        {props.type.indexOf('Omnium') >= 0 && <FontAwesomeIcon icon={faRoad} className="scaledown" style={{ color: getColorForType(props.type)}} />}
                    </div>
                    <div className="titlebox">
                        <div className="title">{props.name}</div>
                        <div className="type">{props.type}</div>
                    </div>
                </div>
                <div className="details">
                    <div className="date date-large">{formatDate(props.date, props.wide)}</div>
                    <div className="date date-small">{formatDate(props.date)}</div>
                    <div className="jerseys-showcase">
                    <div className="jerseys-title">Jersey points available for:</div>
                        <div className="jerseys">
                            
                            {props.jerseys && props.jerseys.map((jersey, index) => (
                                <img key={index} className="jersey" src={getJerseyIconImage(jersey.name)} alt="jersey"></img>
                            ))}
                        </div>
                    </div>
                
                
                </div>
            </div>
        </div>    

 
    );
}

export default Event;