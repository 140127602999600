
import './Card.scoped.css';

function Card(props){
    return (
        <section className={`card ${props.className}`} style={props.style}>
            <div style={props.headerStyle}></div>
            <div className="card-content">
                {props.children}
            </div>
        </section>
    )
}

export default Card;