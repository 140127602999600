import './JerseyEventCategoryMatrix.scoped.css';
import { capitalizeFirstLetter } from '../../utils';

function JerseyEventCategoryMatrix(props){
    
    const getEventCategories = (jec, key) => {
        const cats = jec.eventCategories.filter(ec => ec.name.toLowerCase().indexOf(key.toLowerCase()) > -1);
        if(cats.length === 0){
            return [{ name: '' }];
        }
        else return cats;
    }
    
    return (
        <div className="component">
        {props.jerseyKey && 
            <table cellPadding="0" cellSpacing="0" border="0">
                <thead>
                    <tr>
                        <th>Jersey Category</th><th>Field in This Race</th>
                        { false && <th colSpan="10" className="points">Points Awarded</th>}
                    </tr>
                    { false &&
                    <tr>
                        <th colSpan="2" className="categoryx2"></th>
                        <th className="points">1st</th>
                        <th className="points">2nd</th>
                        <th className="points">3rd</th>
                        <th className="points">4th</th>
                        <th className="points">5th</th>
                        <th className="points">6th</th>
                        <th className="points">7th</th>
                        <th className="points">8th</th>
                        <th className="points">9th</th>
                        <th className="points">10th</th>
                    </tr>}
                </thead>
                <tbody>
                {props.jerseyCategories?.map((jec, index) => (
                    <tr key={index}>
                        <td>{jec.jerseyCategory.categoryName}</td>
                        {getEventCategories(jec, props.eventKey)?.map((ec, idx) => (
                            <td key={idx} className="category">{ec.categoryName}</td>
                        ))}
                        {false && <><td className="points">{jec.firstPlacePoints}</td>
                        <td className="points">{jec.secondPlacePoints}</td>
                        <td className="points">{jec.thirdPlacePoints}</td>
                        <td className="points">{jec.fourthPlacePoints}</td>
                        <td className="points">{jec.fifthPlacePoints}</td>
                        <td className="points">{jec.sixthPlacePoints}</td>
                        <td className="points">{jec.seventhPlacePoints}</td>
                        <td className="points">{jec.eighthPlacePoints}</td>
                        <td className="points">{jec.ninthPlacePoints}</td>
                        <td className="points">{jec.tenthPlacePoints}</td></>}
                    </tr>
                ))}
                </tbody>
            </table>
        }
        </div>
    )
}

export default JerseyEventCategoryMatrix;