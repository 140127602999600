import './InfoPage.scoped.css';
import useFetch from '../../Hooks/useFetch';
import { apiRoot, capitalizeFirstLetter } from "../../utils";
import JerseyCategoryMatrix from '../../Components/JerseyCategoryMatrix/JerseyCategoryMatrix';

function InfoPage(props){
    
    /*const getJerseyCategories1 = (gender, key) => {
        if(!categories) return null;
        return categories["jersey-categories"]?.filter(jc => 
            jc.gender === gender && 
            jc.jersey.name === key);
    }*/
    const getJerseyCategories = (gender) => {
        if(!categories) return null;
        const grps = categories["jersey-categories"]?.filter(jc => 
            jc.gender === gender).reduce((result, jc) => {
                if(!result[jc.jersey.name]) result[jc.jersey.name] = [];
                result[jc.jersey.name].push(jc);
                return result;
            }, { });
            return grps;
    }

    const getFieldSizePoints = () => {
        return [[10,9,8,7,6,5],[9,8,7,6,5,4],[8,7,6,5,4,3],[7,6,5,4,3,2],[6,5,4,3,2,1],[5,4,3,2,1],[4,3,2,1],[3,2,1],[2,1],[1]];
    }

    const hasJersey = (name) => 
        (jerseys.jerseys.find(i => i.name === name))? true: false;
    

    const getJerseyCategoryHeaderData = (gender) => {
        const grps = getJerseyCategories(gender);
        if(!grps) return [];

        return Object.keys(grps).map(key => capitalizeFirstLetter(key));
    }
    const getJerseyCategoryRowData = (gender) => {
        const grps = getJerseyCategories(gender);
        if(!grps) return [];
        let maxLength = 0;
        //console.log('groups', grps);
        Object.keys(grps).forEach(key => {
            if(grps[key].length > maxLength) maxLength = grps[key].length;
        });
        
        const rows = [];
        for(let i=0; i < maxLength; i++){
            const cols = [];
            Object.keys(grps).forEach(key => {
                //console.log('for key', key);
                if(grps[key] && i < grps[key].length){
                    //console.log('adding for', grps[key][i])
                    cols.push(grps[key][i]);
                    //console.log('row', cols);
                }
                else{
                    cols.push({ name: '', categoryName: '' });
                }
            });
            rows.push(cols);
        }
        //console.log('all rows', rows);
        
        return rows;
    }

    const { data: jerseys } = useFetch(apiRoot + '/api/GetData?type=jerseys');
    const { data: categories } = useFetch(apiRoot + '/api/GetData?type=jersey-categories&sort=jersey.id');
    const { data } = useFetch(apiRoot + `/api/GetData?type=${props.section}${props.section !== 'info' ? '&sort=ordinal': ''}`);
    return (
        <div>
            {props.section && <div className="about">
            
            {props.section === 'info' && <section>
                <div className="subsection">
                <h3>Info</h3> 
                {data.info && 
                    <div className="important">{data.info.mainSection}
                    <br/>
                    <br/>
                    <ul>
                        {hasJersey('yellow') && <li>{data.info.yellowDescription}</li>}
                        {hasJersey('polkadot') &&<li>{data.info.polkaDotDescription}</li>}
                        {hasJersey('green') &&<li>{data.info.greenDescription}</li>}
                        {hasJersey('white') &&<li>{data.info.whiteDescription}</li>}
                    </ul>
                    </div>}
                </div>
                <div className="subsection">
                    <h3>Fields</h3> 
                    <p>{data.info && data.info.fieldSection}</p>
                    <h5>Men</h5>
                    <JerseyCategoryMatrix headers={getJerseyCategoryHeaderData('Men')} rows={getJerseyCategoryRowData('Men')} />
                    <br/>
                    <h5>Women</h5>
                    <JerseyCategoryMatrix headers={getJerseyCategoryHeaderData('Women')} rows={getJerseyCategoryRowData('Women')} />
                </div>
            </section>}
            {props.section === 'rules' && <section className="subsection">
                <h3>Rules</h3> 
                    <ul>
                        {data?.rules?.map((rule, index) => (
                            <li key={index}>{rule.text}</li>
                        ))}
                    </ul>
                    <h5>Points and Field Sizes (Yellow and White Jerseys)</h5>
                    <table>
                        <thead>
                        <tr>
                            <th colSpan={6}>
                                Field Size
                            </th>
                        </tr>
                        <tr>
                            <th>10+</th>
                            <th>9</th>
                            <th>8</th>
                            <th>7</th>
                            <th>6</th>
                            <th>5 or fewer</th>
                        </tr>
                        </thead>
                        <tbody>
                        {getFieldSizePoints().map((row, index) => (
                        <tr key={index}>
                            
                            {row.map((points, idx) => (
                                <td key={idx}>{points} {points > 1 ? 'pts' : 'pt'}</td>
                            ))}
                        </tr>
                        
                        ))}
                        </tbody>
                    </table>

            
            </section>}
            {props.section === 'faqs' && <section className="subsection">
                <h3>FAQs</h3> 
                <ul>
                    {data?.faqs?.map((faq, index) => (
                        <li key={index}>
                            <span className="faq-question">{faq.question}</span> 
                            <ul><li>{faq.answer}
                                </li>
                            </ul>
                        </li>
                    ))}
                </ul>
            </section>}
        </div>}
    </div>
    );
};

export default InfoPage;
