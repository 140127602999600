import './Leader.scoped.css';
import { LinkToTop } from '../LinkToTop';
import { cmsRoot } from '../../utils';

function Leader(props) {

    return (
        <div className={`component ${props.jerseyType}-bg`}>
            <div className="leader-avatar" style={{backgroundImage: props.avatar ? `url(${cmsRoot}${props.avatar})` : `url(/images/profile_avatar.png)`}} />
            <div className="leader-info">
                <div className="leader-name">{props.name}</div>
                <div className="leader-points">{props.points}</div>
                <div className="leader-team">{props.team}</div>
            </div>
            <LinkToTop to={`/jerseys/${props.jerseyType}/categories/${props.category}`} className={`more-link ${props.jerseyType}-bg`} href="">Leaderboard</LinkToTop>
        </div>
    )

}

export default Leader;