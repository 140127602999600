class Cache{

    static set(key, value){
        localStorage.setItem(key, JSON.stringify(value));
    }

    static get(key){
        var stored = localStorage.getItem(key);
        return JSON.parse(stored);
    }
}

export default Cache;