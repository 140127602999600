

import './JerseyCategoryMatrix.scoped.css';
import { capitalizeFirstLetter } from "../../utils";
function JerseyCategoryMatrix(props){
    return (
        <table cellPadding="0" cellSpacing="0" border="0">
            <thead>
                <tr>
                {props.headers?.map((cat, index) => 
                (
                    <th key={index}>{cat}</th>
                ))}
                </tr>
            </thead>
            <tbody>
                {props.rows?.map((row, index) => 
                (
                    <tr key={index}>
                        {row.map((col, idx) => (
                                <td key={idx}>{capitalizeFirstLetter(col.categoryName)}</td>
                        ))}
                        
                    </tr>
                ))}
                
            </tbody>
        </table>
    )
}

export default JerseyCategoryMatrix;