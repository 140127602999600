const getJerseyIconImage = (type) => {
    /*switch(type){
        case "green":
            return "/images/jersey_green3.svg";
        case "yellow":
            return "/images/jersey_yellow2.svg";
        case "white":
            return "/images/jersey_white.svg"; 
        case "polkadot":
            return "/images/jersey_dot.svg";       
        default:
            return "/images/jersey_yellow.svg";
    }*/
    return getJerseyImage(type, 200);
};

const getJerseyImage = (type, size) => {
    const sz = size ? `_${size}` : '';
    switch(type){
        case "green":
            return `/images/jersey_render_green${sz}.png`;
        case "yellow":
            return `/images/jersey_render_yellow${sz}.png`;
        case "white":
            return `/images/jersey_render_white${sz}.png`; 
        case "polkadot":
            return `/images/jersey_render_polkadot${sz}.png`;       
        default:
            return `/images/jersey_render_yellow${sz}.png`;
    }
}

const getJerseyForName = (name) => {
    if(name.toLowerCase().indexOf('yellow') > -1) return "yellow";
    if(name.toLowerCase().indexOf('green') > -1) return "green";
    if(name.toLowerCase().indexOf('polkadot') > -1) return "polkadot";
    if(name.toLowerCase().indexOf('white') > -1) return "white";
}


const palette1 = ['#8d99ae','#ff595e','#ff924c','#ffca3a','#c5ca30','#8ac926','#52a675','#1982c4','#4267ac','#6a4c93'];
//const palette2 = ['#FF3368','#14A0FA','#00CC9D','#FFD454','#0F2446','#DD1146','#0280D8','#00AA7B','#DDB232','#0D0224'];
//const palette3 = ['#e39798','#e12729','#f37324','#f8cc1b','#72b043','#007f4e','#166d78','#33558f','#4f3da5','#711fa8'];
//const palette4 = ['#003f5c','#2c4875','#424c81','#58508d','#8a508f','#bc5090','#de5a79','#ff6361','#ff8531','#ffa600'];
//const palette5 = ['#669900', '#99cc33', '#ccee66', '#006699', '#3399cc', '#990066', '#cc3399', '#ff6600', '#ff9900', '#ffcc00'];
const palette = palette1;

const getColorForName = (value) => {
    const hashCode = str => {
        var h = 0, len = str.length, i = 0;
        if ( len > 0 )
            while (i < len)
            h = (h << 5) - h + str.charCodeAt(i++) | 0;
        return h;
    };
    const colors = palette;
    const hashedIndex = Math.abs(hashCode(value)) % 9;
    const color = colors[hashedIndex];
    return color;
}

const getColorForType = (value) => {
    
    if(value.indexOf("Circuit") >= 0)
       return palette[2];
    else if(value.indexOf("Criterium") >= 0)
        return palette[1];
    else if(value.indexOf("Road") >= 0)
        return palette[0];
    else if(value.indexOf("Hill Climb") >= 0)
        return palette[6];
    else if(value.indexOf("Omnium") >= 0)
        return palette[8];
    else
        return palette[7];
}

function capitalizeFirstLetter(string) {
    if(!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const parseDateISOString = (s) => {
    if(!s) return s;
    let ds = s.split(/\D/).map(s => parseInt(s));
    ds[1] = ds[1] - 1; // adjust month
    return new Date(...ds);
}

const formatDate = (dateStr, long) => {
    if(!dateStr) return "TBA";
    if(long)
        return parseDateISOString(dateStr).toLocaleDateString("en-US", { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' });
    else
        return parseDateISOString(dateStr).toLocaleDateString("en-US", { weekday: 'short', month: 'short', day: 'numeric' });
}

const apiRoot = ""; //local managed functions

const cmsRoot = 'https://admin.cyclingpointseries.com/';


export { getJerseyIconImage, getJerseyImage, getColorForName, getJerseyForName, getColorForType, capitalizeFirstLetter, formatDate, apiRoot, cmsRoot };