import CategoryLeaders from '../CategoryLeaders/CategoryLeaders';
import './JerseyLeaderboard.scoped.css';
import { getJerseyImage } from '../../utils';
import { LinkToTop } from '../LinkToTop';

function JerseyLeaderboard(props)
{
    
    //console.log(props);
    return (
        <div className="component">
            <div className="jerseyheader">
                <h4 className={`title ${props.jerseyType}-bg`}>{props.title}</h4>
                <img src={getJerseyImage(props.jerseyType, 400)} className='jersey' alt="jersey" />
                <br/>
                <LinkToTop to={`/jerseys/${props.jerseyType}/events`} className="more-link">Qualifying Events</LinkToTop>
            </div>
            <div className="leaderboard">
                
            {props.categories && props.categories.length > 0 && props.categories.map((cat, index) => (
                <CategoryLeaders key={index} category={cat.name} name={cat.name} leaders={cat.leaders} jerseyType={props.jerseyType} athletes={props.athletes} />
            ))}
            {!props.categories && <div className="empty">No Results Yet!</div>}
            </div>
      </div>
    )
}


export default JerseyLeaderboard;