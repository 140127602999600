import './Footer.scoped.css';

function Footer(props){
    return  (
        <div className="component">
            <div className="poweredBy">Site Powered By</div>
            <a href="https://tech9.com" title="Tech9"><img className="logo" src="/images/tech9-logo.png" alt="Tech9"></img></a>
        </div>
    );
}

export default Footer;