

import { useParams } from "react-router-dom";

import './EventCategoryPage.scoped.css';
import { apiRoot, getJerseyForName } from "../../utils";
import CategoryLeaderboard from "../../Components/CategoryLeaderboard/CategoryLeaderboard";
import useFetch from '../../Hooks/useFetch';
import { Col, Container, Row } from 'react-bootstrap';

function EventCategoryPage(props){
    
    const getEvent = (key) => {
        if(!events.events) return null;   
        else return events.events.find(e => e.key === key);
    }

    const getJerseyCategory = (categoryKey) => {
        if(!jerseyCategories["jersey-categories"]) return null;
        else return jerseyCategories["jersey-categories"]?.find(c => c.name === categoryKey);
    }

    const getEventCategoryResults = (eventKey, categoryKey) => {
        const jerseyType = getJerseyForName(categoryKey);   
        return results?.results?.filter(r => r.jersey_categories.find(c => c.name === categoryKey)).map(r => ({name: r.athlete.name, points: r[`${jerseyType}Points`], place: r.place })).sort((a, b) => a.place - b.place);
    }

    const { data: events } = useFetch(apiRoot + '/api/GetData?type=events');
    const { eventKey, categoryKey } = useParams();
    const { data: jerseyCategories } = useFetch(apiRoot + '/api/GetData?type=jersey-categories&sort=ordinal');
    const { data: results } = useFetch(apiRoot + '/api/GetData?type=results&filters[event][key][$eq]=' + eventKey);
    const { data: athletedata } = useFetch(apiRoot + '/api/GetData?type=athletes');

    //const { data, loading, error, refetch } = useFetch(apiRoot + '/api/GetData?type=eventcategoryresults');
    
    return (
        <div>
             
            {results && <CategoryLeaderboard 
                title={`${getEvent(eventKey).name}, ${getJerseyCategory(categoryKey)?.name}`} jerseyType={getJerseyForName(categoryKey)} 
                athletes={athletedata.athletes}
                category={categoryKey} leaders={getEventCategoryResults(eventKey, categoryKey)}
                event={eventKey}
                eventLogo={getEvent(eventKey)?.logo} 
                />
            }      
        </div>  
    );
}

export default EventCategoryPage;