import Leader from '../Leader/Leader';
import './CategoryLeaders.scoped.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVenus, faMars } from '@fortawesome/free-solid-svg-icons'

function CategoryLeaders(props){

    const getAthlete = (name) => {
        //console.log(props);
        const match = props?.athletes?.find(athlete => athlete.name === name);
        return match;
    }

    const getAthleteAvatar = (name) => {
        const athlete = getAthlete(name);
        const avatarType = `${props.jerseyType}Avatar`;
        
        const avatar = athlete ? athlete[avatarType]?.url : '';
        return avatar;
    }

    const getAthleteTeam = (name) => {
        const athlete = getAthlete(name);
        
        const teamName = athlete ? athlete?.team?.name : '';
        return teamName;
    }

    
    return (
        <div className="component">
            { props.category.startsWith('Women') && <div className="cornerribbon female"><span>Women</span></div>}
            { props.category.startsWith('Men') && <div className="cornerribbon male"><span>Men</span></div>}
            <h5 className="categoryname">{props.name}</h5>
            {props.leaders && props.leaders.length && props.leaders.filter((l, ind) => ind === 0)?.map((leader, index) => (
                <Leader key={index} category={props.category} name={leader.name} avatar={getAthleteAvatar(leader.name)} points={leader.points} team={getAthleteTeam(leader.name)} jerseyType={props.jerseyType} />
            ))}
            
        </div>
    );
}
export default CategoryLeaders;