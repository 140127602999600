

import './EventPage.scoped.css';
import Event from '../../Components/Event/Event';
import useFetch from '../../Hooks/useFetch';
import { useParams } from 'react-router-dom';
import { LinkToTop } from '../../Components/LinkToTop';
import { apiRoot, capitalizeFirstLetter, getJerseyIconImage, getColorForName } from "../../utils";
import JerseySelector from '../../Components/JerseySelector/JerseySelector';
import JerseyEventCategoryMatrix from '../../Components/JerseyEventCategoryMatrix/JerseyEventCategoryMatrix';
import CategoryLeaderboard from "../../Components/CategoryLeaderboard/CategoryLeaderboard";
import Card from '../../Components/Card/Card';
import { Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';

function EventPage(props){
    
    const getEvent = (key) => {
        if(!events.events) return null;   
        else return events.events.find(e => e.key === key);
    }
    const getJerseyEventCategories = (gender, jerseyKey) => {
        if(!categories) return null;
        return categories["jersey-event-categories"]?.filter(jec => 
            jec?.jerseyCategory?.gender === gender && 
            jec?.jerseyCategory?.name?.toLowerCase()?.indexOf(jerseyKey?.toLowerCase()) > -1).sort((a, b) => a?.jerseyCategory?.ordinal - b?.jerseyCategory?.ordinal);
    }

    const getJerseyCategories = (gender, jerseyKey) => {
        if(!jerseyCategories) return null;
        return jerseyCategories["jersey-categories"].filter(c => 
            c?.gender === gender && 
            c?.name?.toLowerCase()?.indexOf(jerseyKey?.toLowerCase()) > -1);
    }

    /*const getResults = (gender, jerseyKey, categoryKey) => {
        return results?.results.filter(r => r.jersey_categories.find(c => c?.name?.toLowerCase()?.indexOf(jerseyKey?.toLowerCase()) > -1 && c?.categoryName === categoryKey && c?.gender === gender )).sort((a, b) => a.place - b.place);
    }*/
    
    const getJerseys = (jerseyKey) => {
        return event?.jerseys?.filter(j => jerseyKey ? j.name === jerseyKey: true);
    }
   
    const { eventKey, jerseyKey } = useParams();
    const { data: events } = useFetch(apiRoot + '/api/GetData?type=events');
    const { data: categories } = useFetch(apiRoot + '/api/GetData?type=jersey-event-categories');
    const { data: jerseyCategories } = useFetch(apiRoot + '/api/GetData?type=jersey-categories&sort=ordinal');
    //const { data: results } = useFetch(apiRoot + '/api/GetData?type=results&filters[event][key][$eq]=' + eventKey);
    
    const event = getEvent(eventKey);
    
    
    return (
        <div>
            {event && 
                <Container>
                        <div className="header">
                            <JerseySelector jersey={jerseyKey} jerseys={event?.jerseys} linkRoute={`/events/${eventKey}/jerseys/:key`} resetRoute={`/events/${eventKey}`} />
                                <div className="description">{jerseyKey ? event?.jerseys && `Event Info for ${event.jerseys.find(j => jerseyKey === j.name)?.type}`: ''} 
                                {jerseyKey ? <LinkToTop replace className="reset" to={`/events/${eventKey}`}>&nbsp;&nbsp;<FontAwesomeIcon icon={faCircleXmark} /></LinkToTop> : <div></div>}
                                </div>
                                <Event eventKey={event.key} name={event.name} date={event.date} type={event.type} jerseys={event.jerseys} wide /> 
                                { false && <JerseySelector jersey={jerseyKey} jerseys={event.jerseys} linkRoute={`/events/${event.key}/jerseys/:key`} resetRoute={`/events/${event.key}`} />}
                            </div>
                            {getJerseys(jerseyKey).map((jersey, i) => (
                                <Card className={`${jersey.name}-bg ${jersey.name}-border`} key={i}>
                                    <div className='group'>
                                        <div className={`subsection main ${jersey.name}-bg`}>
                                            <div className="flex">
                                                <div className="jerseybox">
                                                    <img className="jersey" src={getJerseyIconImage(jersey.name)} alt="Jersey"></img>
                                                </div>
                                                <div>
                                                    <div className="jerseytitle">{capitalizeFirstLetter(jersey.name)} Jersey</div>
                                                    <div className="jerseysubtitle">Fields and Results</div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className='subsection gender'>
                                            <h3>Men</h3>
                                            <Row>
                                                <Col xs={12} lg={5}>
                                                    <div className='subsection fields'>
                                                        <h5>Race Fields</h5>
                                                        <JerseyEventCategoryMatrix jerseyCategories={getJerseyEventCategories('Men', jersey.name)} eventKey={eventKey} jerseyKey={jersey.name} />
                                                    </div>
                                                </Col>
                                                <Col xs={12} lg={7}>
                                                    <div className='subsection results'>
                                                        <h5>Results</h5>
                                                        {getJerseyCategories('Men', jersey.name).map((cat, idx) => (
                                                                <div key={idx} className="link">
                                                                    <LinkToTop className="results-link" key={idx} to={`/events/${eventKey}/categories/${cat.name}`}>{cat.categoryName}</LinkToTop>
                                                                </div>
                                                        ))}  
                                                    </div>  
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className='subsection gender'>
                                            <h3>Women</h3>
                                            <Row>
                                                <Col xs={12} lg={5}>
                                                    <div className='subsection fields'>
                                                        <h5>Race Fields</h5>
                                                        <JerseyEventCategoryMatrix jerseyCategories={getJerseyEventCategories('Women', jersey.name)} eventKey={eventKey} jerseyKey={jersey.name} />
                                                    </div>
                                                </Col>
                                                    <Col xs={12} lg={7}>
                                                    <div className='subsection results'>
                                                    
                                                        <h5>Results</h5>
                                                    
                                                    
                                                        {getJerseyCategories('Women', jersey.name).map((cat, idx) => (
                                                                <div key={idx} className="link">
                                                                    <LinkToTop className="results-link" key={idx} to={`/events/${eventKey}/categories/${cat.name}`}>{cat.categoryName}</LinkToTop>
                                                                </div>
                                                        ))}
                                                    
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Card>
                            ))}
                </Container>
            }
        </div>
    );
}

export default EventPage;