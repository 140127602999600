import { useEffect, useState } from "react";
import axios from "axios";
import Cache from "../cache";

const ttlMillis = 20 * 60 * 1000;

function useFetch(url) {
    const [data, setData] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        
        const entry = Cache.get(url);
        //console.log(entry);
        //const elapsed = entry && Math.abs(Date.now() - new Date(entry.cached));
        //if was fetched at least 20 min ago, keep it.
        if(entry){
            console.log('fetching from cache:', url);
            setData(entry);
            refetch();
        }
        else{
            console.log('fetching data:', url);
            setLoading(true);
            axios
            .get(url)
            .then((response) => {
                console.log('received response:', url, response);
                Cache.set(url, response.data);
                setData(response.data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
            });
        }
    }, [url]);

    const refetch = () => {
        console.log('refetching data:', url);
        setLoading(true);
        axios
        .get(url)
        .then((response) => {
            Cache.set(url, response.data);
            setData(response.data);
        })
        .catch((err) => {
            setError(err);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    return { data, loading, error, refetch };
}

export default useFetch;
