import './Header.scoped.css';
import { LinkToTop } from '../LinkToTop';

function Header(props){
    return  (
        <div className="component">
            <h2 className='title-box'>
                <a href="https://voltcyclewear.com" title="title sponsor">
                    <img src="/images/volt_trans_wht.png" className="logo" alt="title" />
                </a>
           
            <span className="title">Utah Cycling Point Series 2024
                <p className="subtitle">A season-long competition for the Volt Cyclewear leaders jerseys</p>
            </span>
            </h2>
            <section className="menu">
                <LinkToTop className="menuButton" to="/">Leaders</LinkToTop>
                <LinkToTop className="menuButton" to="/info">Info</LinkToTop>
                <LinkToTop className="menuButton" to="/rules">Rules</LinkToTop>
                <LinkToTop className="menuButton" to="/events">Events</LinkToTop>
                <LinkToTop className="menuButton" to="/faqs">FAQ</LinkToTop>
            </section>
        </div>
    );
}

export default Header;