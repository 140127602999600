
import './JerseysPage.scoped.css';
import '../../Components/JerseyLeaderboard/JerseyLeaderboard';
import JerseyLeaderboard from '../../Components/JerseyLeaderboard/JerseyLeaderboard';
import useFetch from '../../Hooks/useFetch';
import { apiRoot } from "../../utils";


function JerseysPage() {

  const { data: jerseydata } = useFetch(apiRoot + '/api/GetData?type=jerseys&sort=id');
  const { data: jerseyleaderdata } = useFetch(apiRoot + '/api/GetData?type=jerseyleaders');
  const { data: athletedata } = useFetch(apiRoot + '/api/GetData?type=athletes');
 
  const getJerseyCategories = (jersey) => {
    const jerseyData = jerseyleaderdata?.jerseyleaders?.find(c => c.jersey === jersey);
    //console.log(filtered);
    return jerseyData?.categories;
  }
  
  return (
    <div className="app">
        <div className="component">
          {jerseydata?.jerseys?.map((jersey, index) => (
            <JerseyLeaderboard className="jerseyleaderboard" key={index} jerseyType={jersey.name} title={jersey.title} categories={getJerseyCategories(jersey.name)} athletes={athletedata.athletes} />
          ))}
        </div>    

    </div>
  );
}

export default JerseysPage;
