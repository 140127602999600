import { useParams } from "react-router-dom";

import './JerseyCategoryPage.scoped.css';
import CategoryLeaderboard from "../../Components/CategoryLeaderboard/CategoryLeaderboard";
import useFetch from '../../Hooks/useFetch';
import { apiRoot } from "../../utils";

function JerseyCategoryPage(props){
    
    const getJersey = (jerseyType) => 
        jerseydata.jerseys.find(j => j.name === jerseyType);
    
    /*const getCategory = (jerseyType, categoryKey) =>
        getJersey(jerseyType).categories.find(c => c.name === categoryKey);
    */
    const getCategoryLeaders = (jerseyType, categoryKey) =>
        jerseyleaderdata.jerseyleaders.find(j => j.jersey === jerseyType).categories.find(c => c.name === categoryKey).leaders;
    
    //const getCategoryEvents = (jerseyType, categoryKey) =>
    //    jerseycategorydata.jerseys.find(j => j.name === jerseyType).events;

    const { data: jerseydata } = useFetch(apiRoot + '/api/GetData?type=jerseys');
    const { data: jerseyleaderdata } = useFetch(apiRoot + '/api/GetData?type=jerseyleaders');
    const { data: athletedata } = useFetch(apiRoot + '/api/GetData?type=athletes');
    //const { data: jerseycategorydata } = useFetch(apiRoot + '/api/GetData?type=jersey-categories');
    const { categoryKey, jerseyType } = useParams();
    return (
        <div>
        {jerseydata && jerseyleaderdata && <CategoryLeaderboard jerseyType={jerseyType} 
            title={`${getJersey(jerseyType).title}, ${categoryKey}`} athletes={athletedata.athletes}
            category={categoryKey} leaders={getCategoryLeaders(jerseyType, categoryKey)} 
            //events={getCategoryEvents(jerseyType, categoryKey)} 
            />}
        </div>
    );
}

export default JerseyCategoryPage;