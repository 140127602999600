
import './JerseySelector.scoped.css';
import { getJerseyIconImage } from '../../utils';
import { LinkToTop } from '../LinkToTop';

function JerseySelector(props) {
    
    const getLink = (jerseyKey) => {
        return props?.linkRoute?.replace(':key', jerseyKey);
    }
    return (
        <div className="jersey">
            {props.jersey && <LinkToTop replace to={props.resetRoute}><img src={getJerseyIconImage(props.jersey)} alt="Jersey"></img></LinkToTop>}
            {!props.jersey && props.jerseys && props.jerseys.map((jersey, index) => (
                <LinkToTop replace key={index} to={getLink(jersey.name)}><img src={getJerseyIconImage(jersey.name)} alt="Jersey"></img></LinkToTop>
            ))}
        </div>
    );
}

export default JerseySelector;