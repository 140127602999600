import { getJerseyIconImage } from '../../utils';
import './CategoryLeaderboard.scoped.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAward, faMedal, faTrophy, faVenus, faMars, faFire } from '@fortawesome/free-solid-svg-icons';
import { LinkToTop } from '../LinkToTop';
import { getColorForName } from '../../utils';

function CategoryLeaderboard(props){

    const getAthlete = (name) => {
        //console.log(props);
        const match = props?.athletes?.find(athlete => athlete.name === name);
        return match;
    }

    const getAthleteAvatar = (name) => {
        const athlete = getAthlete(name);
        const avatarType = `${props.jerseyType}Avatar`;
        
        const avatar = athlete ? athlete[avatarType]?.url : '';
        return avatar;
    }

    const getAthleteTeam = (name) => {
        const athlete = getAthlete(name);
        
        const teamName = athlete?.team?.name ?? <span>&nbsp;</span>;
        return teamName;
    }

    return (
        <div className="component">
            <div className="titlebar">
                { props.category.startsWith('Women') && <div className="cornerribbon female"><span>Women</span></div>}
                { props.category.startsWith('Men') && <div className="cornerribbon male"><span>Men</span></div>}
                {props.jerseyType && <div className='jerseybox'><img src={getJerseyIconImage(props.jerseyType)} className='jersey' alt="jersey" /></div>}
                {props.jerseyType && <h4 className={`leaderboard-title ${props.jerseyType}-bg`}>
                    <span className="text">{props.title}</span>
                    <span className="leaderboard-subtitle">{props.event ? 'Event ' : ''}Leaderboard</span> 
                </h4>}
                
            </div>
            
            <div className={`leaderboard ${props.jerseyType}-border`}>
                <div className="leaders">
                    <div className={`leaderhead ${props.jerseyType}-bg`}>
                        <div></div>
                        <div>Place</div>
                        <div>Name</div>
                        <div className="team-colhead">Team</div>
                        <div>Points</div>
                    </div>
                    <div className="leaderrows">
                        {!props.leaders?.length && <div className="leaderrow">
                            <div className='trophies'></div>
                            <div></div>
                            <div>No Results Yet!</div>
                            <div></div>
                            <div></div>
                        </div>}
                        
                        {props.leaders.map((leader, index) => (
                            <div key={index} className="row">
                                <div className={`leaderrow ${getPlaceClass(leader.place, props.jerseyType)}`}>
                                    <div className="trophies">
                                        {leader.place === 1 && <FontAwesomeIcon icon={faTrophy} />}
                                        {leader.place === 2 && <FontAwesomeIcon icon={faMedal} />}
                                        {leader.place === 3 && <FontAwesomeIcon icon={faAward} />}
                                        {leader.place > 3 && <span className="space"></span>}
                                    </div>
                                    <div>{leader.place}</div>
                                    <div>{leader.name}<div className="team-text">{getAthleteTeam(leader.name)}</div></div>
                                    <div className="team-col">{getAthleteTeam(leader.name)}</div>
                                    <div>{leader.points}</div>
                                </div>
                                <div className="scores">
                                    {leader.eventPoints && leader.eventPoints.map((event, idx) => ( 
                                            <LinkToTop key={idx} to={`/events/${event.key}/categories/${props.category}`}>
                                                <span className="score" style={{ backgroundColor: getColorForName(event.key)}}>{event.points}</span>
                                            </LinkToTop>
                                        ))
                                    }
                                    {leader.jerseyPoints && leader.jerseyPoints.map((jersey, idx) => ( 
                                            <LinkToTop key={idx} to={`/jerseys/${jersey.key}/categories/${props.category}`}>
                                                <span className={`score score-jersey ${jersey.key}`} style={{ backgroundImage: `url(${getJerseyIconImage(jersey.key)}`}}>
                                                    <br/>
                                                    <span className="score-number">{jersey.points}</span>
                                                </span>
                                            </LinkToTop>
                                        ))
                                    }
                                </div>
                                
                            </div>
                        ))}
                    </div>
                </div>
                
            </div>
        </div>
    );
};

const getPlaceClass = (place, jerseyType) => {
    switch(place){
        case 1:
            return `first ${jerseyType}`;
        case 2:
            return `second`;
        case 3:
            return `third`;
        default:
            return '';
    }
}

export default CategoryLeaderboard;