import JerseysPage from './Pages/JerseysPage/JerseysPage';
import JerseyCategoryPage from './Pages/JerseyCategoryPage/JerseyCategoryPage';
import EventCategoryPage from './Pages/EventCategoryPage/EventCategoryPage';
import EventsPage from './Pages/EventsPage/EventsPage';
import EventPage from './Pages/EventPage/EventPage';
import InfoPage from './Pages/InfoPage/InfoPage';
import LayoutPage from './Pages/LayoutPage/LayoutPage';

const routes = [
    {
      path: "/",
      element: <LayoutPage><JerseysPage /></LayoutPage>,
      //errorElement: <div>Whoops!</div>
    },
    {
        path: "/jerseys/:jerseyType/categories/:categoryKey",
        element: <LayoutPage><JerseyCategoryPage /></LayoutPage>,
        //errorElement: <div>Whoops!</div>
    },
    {
        path: "/events/:eventKey/categories/:categoryKey",
        element: <LayoutPage><EventCategoryPage /></LayoutPage>,
        //errorElement: <div>Whoops!</div>
    },
    {
        path: "/jerseys/:jerseyType/events",
        element: <LayoutPage><EventsPage /></LayoutPage>,
        //errorElement: <div>Whoops!</div>
    },
    {
        path: "/info",
        element: <LayoutPage><InfoPage section="info" /></LayoutPage>
    },
    {
        path: "/rules",
        element: <LayoutPage><InfoPage section="rules" /></LayoutPage>
    },
    {
        path: "/events",
        element: <LayoutPage><EventsPage /></LayoutPage>
    },
    {
        path: "/events/:eventKey",
        element: <LayoutPage><EventPage /></LayoutPage>
    },
    {
        path: "/events/:eventKey/jerseys/:jerseyKey",
        element: <LayoutPage><EventPage /></LayoutPage>
    },
    {
        path: "/faqs",
        element: <LayoutPage><InfoPage section="faqs" /></LayoutPage>
    }
]

export default routes;