import './EventsPage.scoped.css';
import EventList from '../../Components/EventList/EventList';
import useFetch from '../../Hooks/useFetch';
import { useParams } from 'react-router-dom';
import { apiRoot } from "../../utils";

function JerseyEventsPage(){

    const { jerseyType } = useParams();
    const { data: events } = useFetch(apiRoot + '/api/GetData?type=events&sort=date');
    const { data: jerseys } = useFetch(apiRoot + '/api/GetData?type=jerseys');
    
    const getEvents = (jersey) => {
        if(!events.events) return [];   
        else return jersey ? events.events.filter(e => e.jerseys.map(j => j.name).indexOf(jersey) !== -1) : events.events;
    }
    return (
        <EventList events={getEvents(jerseyType)} jersey={jerseyType} jerseys={jerseys.jerseys} />
    );
}   

export default JerseyEventsPage;