
import './EventList.scoped.css';
import { LinkToTop } from '../LinkToTop';
import Event from '../../Components/Event/Event';
import JerseySelector from '../JerseySelector/JerseySelector';
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';

function EventList(props){
    //console.log(props);
    return (
        <div className="component">
            <JerseySelector jersey={props.jersey} jerseys={props.jerseys} linkRoute={'/jerseys/:key/events'} resetRoute={'/events'} />
            <div className="description">{props.jersey ? props.jerseys && `Events for ${props.jerseys.find(j => props.jersey === j.name)?.type}`: 'All Events'} 
            {props.jersey ? <LinkToTop replace className="reset" to="/events">&nbsp;&nbsp;<FontAwesomeIcon icon={faCircleXmark} /></LinkToTop> : <div></div>}
            </div>
            { props.events &&
            <div className="events">
                {props.events.map((event, index) => (
                    <LinkToTop replace className="eventlink" key={index} to={props.jersey ? `/events/${event.key}/jerseys/${props.jersey}` : `/events/${event.key}`}>
                        <Event eventKey={event.key} name={event.name} date={event.date} type={event.type} jerseys={event.jerseys} />
                    </LinkToTop>
                ))}
            </div>
            }
        </div>
    );
};


export default EventList;